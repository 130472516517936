@tailwind base;
@tailwind components;
@tailwind utilities;

.root,
#root,
#docs-root {
  --primary: #04223A;
  --secondary: #F7F8FB;
  --accent: #F5A623;
  --background: #fff;
  --main: #0d0106;
  --header: #2d3748;
}

html {
  scroll-behavior: smooth;
}
@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

.expandButtonIcon {
  height:0px;
  transition: all 300ms ease-in-out;
  fill: var(--background);
}
.expandButton {
  transition: all 300ms ease-in-out;
}
.expandButton:hover .expandButtonIcon {
  height: 20px;
  margin-left: 32px;

}